import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { makeRequest } from '../../helpers'
import CareersView from './careers-view'

const CareersController = () => {
  const [loading, setLoading] = useState(true)
  const [careers, setCareers] = useState([])
  const { i18n } = useTranslation()
  const getCareers = async () => {
    setLoading(true)

    const headers = {
      'Content-Type': 'application/json',
    }
    makeRequest({
      headers,
      endPoint: 'page',
      params: {
        slug: 'careers',
        lang: i18n.language,
      },
    })
      .then(({ data }) => {
        setCareers(data.content)
        setLoading(false)
      })
      .catch((error) => {
        console.error(error)
        setLoading(false)
      })
  }

  useEffect(() => {
    getCareers()
  }, [i18n.language])

  const viewProps = {
    loading,
    data: careers,
  }
  return <CareersView {...viewProps} />
}

export default CareersController
